/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
    object, shape, string, bool,
} from 'prop-types';
import { trackEvent } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { getCurrentPageType } from '../../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Selectors';

const styles = (theme) => ({
    order_tracking_link: {
        textDecoration: 'none',
        letterSpacing: '0.42px',
        [theme.breakpoints.up(768)]: {
            fontSize: '13px',
        },
        [theme.breakpoints.up(1025)]: {
            fontSize: '16px',
            borderRadius: '6px',
        },
    },
    trackingText: {
        fontWeight: 'bold',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
        },
        marginRight: '6px',
    },
    container: {
        display: 'flex',
        alignItems: 'center',
        width: 'auto',
        height: '35px',
        borderRadius: '6px',
    },
    truckIcon: {
        height: '29px',
        width: '29px',
        marginRight: '6px',
        marginLeft: '6px',
    },
});

const GraphqlUNTOrderTracking = ({ classes, orderTracking }) => {
    const dispatch = useDispatch();
    const pageType = useSelector(getCurrentPageType);
    useEffect(() => {
        if (orderTracking?.tracking_for_impression) {
            dispatch(trackEvent({
                eventCategory: orderTracking?.tracking_for_impression?.tracking_event_category || '',
                eventAction: orderTracking?.tracking_for_impression?.tracking_event_action || '',
                eventLabel: orderTracking?.tracking_for_impression?.tracking_event_label || '',
                nonInteraction: true,
            }));
        }
    }, []);

    const handleClickEvent = () => {
        if (orderTracking?.tracking_for_click) {
            dispatch(trackEvent({
                eventCategory: orderTracking?.tracking_for_click?.tracking_event_category || '',
                eventAction: orderTracking?.tracking_for_click?.tracking_event_action || '',
                eventLabel: `${orderTracking?.tracking_for_click?.tracking_event_label || ''} ${pageType}`,
                eventValue: '1',
            }));
        }
    };

    return (
        <Link
            to={orderTracking?.link?.href}
            onClick={handleClickEvent}
            style={{ backgroundColor: orderTracking?.background_color || '' }}
            className={classes.order_tracking_link}
        >
            <div className={classes.container}>
                <img className={classes.truckIcon} src={orderTracking?.icon?.url} alt="" />
                <p style={{ color: orderTracking?.font_color || '' }} className={classes.trackingText}>{orderTracking?.link?.title}</p>
            </div>
        </Link>
    );
};

GraphqlUNTOrderTracking.propTypes = {
    classes: object.isRequired,
    orderTracking: shape({
        background_color: string.isRequired,
        font_color: string.isRequired,
        icon: shape({
            url: string.isRequired,
        }).isRequired,
        link: shape({
            href: string.isRequired,
            title: string.isRequired,
        }).isRequired,
        show: bool.isRequired,
        tracking_for_impression: shape({
            tracking_event_category: string,
            tracking_event_action: string,
            tracking_event_label: string,
        }),
        tracking_for_click: shape({
            tracking_event_category: string,
            tracking_event_action: string,
            tracking_event_label: string,
        }),
    }).isRequired,
};

export default withStyles(styles)(GraphqlUNTOrderTracking);
