/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState, useRef, useEffect } from 'react';
import {
    string, shape, arrayOf, object, func,
} from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    slider: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down(1025)]: {
            width: '100%',
        },
    },
    arrowContainer: {
        background: 'transparent',
        margin: 0,
        border: 'none',
        padding: '5px 5px 5px 0',
        outline: '#65388b',
        cursor: 'pointer',
        [theme.breakpoints.down(600)]: {
            padding: '3px 6px',
        },
    },
    arrow: (linkStyleOverrides) => ({
        width: '16px',
        height: '16px',
        color: linkStyleOverrides.color || '#000',
        [theme.breakpoints.down(600)]: {
            width: '14px',
            height: '14px',
        },
    }),
    flipArrow: {
        transform: 'scaleX(-1)',
    },
    sliderContainer: (linkStyleOverrides) => ({
        width: linkStyleOverrides.sliderWidthOverride || '450px',
        padding: '0',
        textAlign: 'center',
        paddingBottom: '2px', // for firefox
    }),
    slide: {
        opacity: 0,
        '-webkit-transform': 'translate(15px)', // for safari
        transform: 'translateX(15px)',
        transition: 'opacity 0.66s ease-in-out, transform 0.44s ease-in-out',
        [theme.breakpoints.down(600)]: {
            '-webkit-transform': 'translate(0px)', // for safari
        },
    },
    slideContent: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
            display: 'block',
            width: '25px',
            marginRight: '10px',
            [theme.breakpoints.down(600)]: {
                width: '15px',
                marginRight: '5px',
            },
        },
    },
    active: {
        opacity: 1,
        '-webkit-transform': 'translate(0px)', // for safari
        transform: 'translateX(0)',
        transition: 'opacity 0.66s ease-in-out, transform 0.44s ease-in-out',
    },
    link: (linkStyleOverrides) => ({
        fontWeight: 'bold',
        color: linkStyleOverrides.color || '#000',
        fontSize: linkStyleOverrides.fontSize || '15px',
        textDecoration: 'none',
    }),
}));

const MultiUntSlider = ({
    multiUntSliderData, linkStyleOverrides, rotateSpeedOverride, trackEvent,
}) => {
    const [current, setCurrent] = useState(0);
    const length = multiUntSliderData.length;
    const timeout = useRef(null);
    const rotateSpeed = Number.parseInt(rotateSpeedOverride, 10) || 3700;

    useEffect(() => {
        const nextSlide = () => {
            setCurrent((curr) => (curr === length - 1 ? 0 : curr + 1));
        };
        timeout.current = setTimeout(nextSlide, rotateSpeed);
        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        };
    }, [current, length]);

    const next = () => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
        setCurrent(current === length - 1 ? 0 : current + 1);
        trackEvent({
            eventCategory: 'UNT',
            eventAction: 'Slider Click',
            eventLabel: 'Right',
            queued: true,
        });
    };
    const previous = () => {
        if (timeout.current) {
            clearTimeout(timeout.current);
        }
        setCurrent(current === 0 ? length - 1 : current - 1);
        trackEvent({
            eventCategory: 'UNT',
            eventAction: 'Slider Click',
            eventLabel: 'Left',
            queued: true,
        });
    };

    const classes = useStyles(linkStyleOverrides);

    return (
        <div className={classes.slider}>
            <button className={classes.arrowContainer} type="button" aria-label="Previous offer" onClick={previous}>
                <ArrowBackIosIcon className={classes.arrow} />
            </button>
            <div className={classes.sliderContainer}>
                {multiUntSliderData.map((slider, i) => (
                    // eslint-disable-next-line no-underscore-dangle
                    <div key={slider._metadata.uid} className={i === current ? `${classes.slide} ${classes.active}` : `${classes.slide}`}>
                        {i === current && (
                            <div className={classes.slideContent}>
                                {slider?.image?.url && <img src={slider.image.url} alt={slider.slider_link.title} />}
                                <Link
                                    to={slider.slider_link.href}
                                    className={classes.link}
                                    onClick={() => {
                                        trackEvent({
                                            eventCategory: 'UNT',
                                            eventAction: slider.slider_link.title,
                                            eventLabel: '<<pageType>>',
                                        });
                                    }}
                                >
                                    {slider.slider_link.title}
                                </Link>
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <button className={classes.arrowContainer} type="button" aria-label="Next offer" onClick={next}>
                <ArrowBackIosIcon className={`${classes.arrow} ${classes.flipArrow}`} />
            </button>
        </div>
    );
};

MultiUntSlider.defaultProps = {
    multiUntSliderData: [],
    rotateSpeedOverride: '',
};

MultiUntSlider.propTypes = {
    multiUntSliderData: arrayOf(shape({
        link: string.isRequired,
        href: string.isRequired,
    })),
    linkStyleOverrides: object.isRequired,
    rotateSpeedOverride: string,
    trackEvent: func.isRequired,
};

export default MultiUntSlider;
