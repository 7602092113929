/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useState, useEffect } from 'react';
import {
    string, shape, func, bool,
} from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { connect, useSelector } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import DialogContent from '@material-ui/core/DialogContent';
import { bindActionCreators } from 'redux';
import FormHelperText from '@material-ui/core/FormHelperText';
import { createEmailSubscription, resetEmailSubscription } from '../../../../state/ducks/App/App-Actions';
import { getEmailSubscriptionStatus } from '../../../../state/ducks/App/App-Selectors';
import { getFeatureFlag, getPresentationFamily } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import getBackgroundColor from '../../../helpers/getBackgroundColor';
import { hideLabelWithAssistiveTech } from '../../../helpers/common/helper';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '100%',
        [theme.breakpoints.down(768)]: {
            margin: '10px',
        },
    },
    textField: {
        border: 'none',
        padding: '7px',
        width: '305px',
        marginRight: '10px',
    },
    button: (styles) => ({
        background: styles?.backgroundColor || theme.palette.black || '#000',
        color: theme.palette.white || '#fff',
        borderRadius: '0',
        lineHeight: '1.1',
        padding: '7px',
        fontWeight: 'bold',
        minWidth: '90px',
        width: 110,
        '&:hover': {
            backgroundColor: styles?.backgroundColor || theme.palette.black || '#000',
        },
    }),
    dialogTitle: {
        fontSize: '14px',
        fontWeight: '600',
    },
    modalBox: {
        minHeight: '100px',
        minWidth: '300px',
    },
    fixedHeader: {
        width: '300px',
        position: 'fixed',
    },
    dialogContent: {
        overflowY: 'auto',
    },
    closeBtn: {
        position: 'absolute',
        right: '0',
        top: '0',
    },
    progress: {
        margin: '4px',
        color: theme.palette.black,
    },
    subscribedText: {
        '& > p': {
            margin: '5px 0',
        },
    },
    title: {
        fontSize: '16px',
    },
    emailContainer: {
        width: '100%',
        position: 'relative',
    },
    inputContainer: {
        width: '100%',
        display: 'flex',
    },
    imageContainer: {
        margin: '0px 11px 0px 0px',
        maxWidth: '47px',
    },
    footerV3Container: {
        width: 'auto',
        margin: '20px 0px 40px 0px',
        '& $textField': {
            width: '100%',
            marginRight: '0px',
            height: '40px',
            padding: '7px 7px 7px 15px',
            fontSize: '14px',
            border: '0.5px solid #1F1F1B',
            '&:focus-visible': {
                outline: 'none',
                border: '0.5px solid #1F1F1B',
            },
        },
        '& $button': {
            width: '104px',
            padding: '6px 0px',
            '& .MuiButton-label': {
                paddingLeft: '10px',
            },
        },
        '& $inputContainer': {
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
        },
        '& $title': {
            fontWeight: '600',
        },
        [theme.breakpoints.down(1366)]: {
            '& $title': {
                fontWeight: '700',
            },
        },
        [theme.breakpoints.down(1024)]: {
            margin: '20px 0px 0px',
            padding: ' 0px',
            '& $textField': {
                width: '75%',
            },
            '& $button': {
                width: '25%',
            },
        },
        [theme.breakpoints.down(767)]: {
            width: '100%',
            padding: ' 0px 12px 30px',
            margin: '20px 0px 0px 0px',
            '& $button': {
                width: '30%',
            },
        },
    },
    helperText: {
        position: 'absolute',
        color: '#DF3030',
        display: 'flex',
        alignItems: 'center',
        marginTop: '10px',
        '& svg': {
            marginRight: '10px',
        },
    },
    emailLabel: {
        display: 'flex',
        alignItems: 'center',
    },
    ariaInvisible: () =>  hideLabelWithAssistiveTech(),
}));

const EmailSubscribeBox = ({
    data = {}, handleSubscribe, emailSubscriptionStatus, resetSubscriptionData,
}) => {
    const styleObj = {};
    data?.style_overrides?.forEach((style) => {
        const styleKey = Object.keys(style)[0];
        switch (styleKey) {
            case 'button_bg_color':
                styleObj.backgroundColor = getBackgroundColor(style[styleKey]?.color);
                break;
            default:
                // do nothing
        }
    });

    const classes = useStyles(styleObj);
    const [email, setEmail] = useState('');
    const [displayDialog, setDisplayDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [subscriptionSuccessful, setSubscriptionSuccessful] = useState(false);
    const isFood = useSelector(getPresentationFamily) === 'food';
    const footerV3Enabled = useSelector(getFeatureFlag('is-footer-v3-enabled')) && isFood;

    useEffect(() => {
        const { isSubscribed, error } = emailSubscriptionStatus;
        if (error?.message?.length) {
            setErrorMessage(error.message);
            setEmail('');
            setDisplayDialog(true);
        }
        if (isSubscribed) {
            setEmail('');
            setSubscriptionSuccessful(true);
        }
    }, [emailSubscriptionStatus]);

    const emailValidation = (value) => {
        const regex = /\S+@\S+\.\S+/; // The regular expression to validate the email pattern
        if (value === '' ||  value === undefined) {
            setErrorMessage('Please enter email address');
            setDisplayDialog(true);
            return false;
        } if (!regex.test(value)) {
            setErrorMessage('Enter a valid email address ');
            setDisplayDialog(true);
            return false;
        }
        return true;
    };

    const onChangeSubscribe = (value) => {
        if (emailValidation(value)) {
            setErrorMessage('');
            handleSubscribe({ email: value });
        }
    };
    if (subscriptionSuccessful && data.subscribe_successful_copy?.length) {
        return (
            <div className={classes.subscribedText}>
                <ReactMarkdown source={data.subscribe_successful_copy} escapeHTML={false} />
            </div>
        );
    }

    const closeErrorDialog = () => {
        setDisplayDialog(false);
        resetSubscriptionData();
    };

    return (
        <div className={`${classes.wrapper} ${footerV3Enabled ? classes.footerV3Container : ''}`}>
            <div className={classes.emailContainer}>
                <label className={footerV3Enabled ? classes.emailLabel : classes.ariaInvisible} htmlFor={data.input_placeholder}>
                    {data?.icon?.url && <span className={classes.imageContainer}><img className={classes.icon} src={data.icon.url} alt="email-icon" /></span>}
                    <span className={classes.title}>{data.title || 'subscribe email'}</span>
                </label>
                <div className={classes.inputContainer}>
                    <input
                        aria-label={`${data.input_placeholder}${'subscribe'}`}
                        aria-invalid="false"
                        autoComplete="off"
                        className={classes.textField}
                        id="subscribe_email_field"
                        name="email"
                        placeholder={data.input_placeholder || 'Enter email address'}
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Button
                        variant="contained"
                        type="button"
                        className={classes.button}
                        onClick={() => onChangeSubscribe(email)}
                    >
                        {data.button_text || 'SUBSCRIBE'} {footerV3Enabled && <ChevronRightIcon className={classes.chevron} />}
                    </Button>
                </div>
                {(errorMessage && footerV3Enabled) && <FormHelperText className={classes.helperText}><ErrorOutlineIcon /> {errorMessage}</FormHelperText> }
            </div>

            {!footerV3Enabled && (
                <Dialog
                    classes={{
                        paper: classes.modalBox,
                    }}
                    open={displayDialog}
                    onClose={() => closeErrorDialog()}
                >
                    <DialogTitle>
                        <div className={classes.dialogTitle}>
                            Email Subscribe
                            <IconButton className={classes.closeBtn} aria-label="Close" onClick={() => closeErrorDialog()}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContent}>
                        {errorMessage}
                    </DialogContent>
                </Dialog>
            ) }
        </div>
    );
};

EmailSubscribeBox.propTypes = {
    data: shape({
        subscribe_successful_copy: string,
        input_placeholder: string,
        button_text: string,
    }).isRequired,
    handleSubscribe: func.isRequired,
    emailSubscriptionStatus: shape({
        isSubscribed: bool,
        error: shape({
            message: string,
        }),
    }).isRequired,
    resetSubscriptionData: func.isRequired,
};

const mapStateToProps = (state) => ({
    emailSubscriptionStatus: getEmailSubscriptionStatus(state),
});

const mapDispatchToProps = (dispatch) => ({
    handleSubscribe: bindActionCreators(createEmailSubscription, dispatch),
    resetSubscriptionData: bindActionCreators(resetEmailSubscription, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailSubscribeBox);
