/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
    object, func, string, shape,
} from 'prop-types';
import { useSelector } from 'react-redux';
import { getFeatureFlag } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';

import { trackData } from '../../../../helpers/tracking/common/commonTrackingHelpers';
import GraphqlUNTOrderTracking from './GraphqlUNTOrderTracking';
import EmailSubscribeBox from '../../../ContentTypeComponents/EmailSubscrube/EmailSubscribeBox';
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage';
import MultiUntSlider from './MultiUntSlider';
import { selectors } from '../../../../../state/ducks/App';

const useStyles = makeStyles((theme) => ({
    siteContainer: {
        maxWidth: '1400px',
        margin: '0 auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    unt: (data) => ({
        padding: '7px 15px',
        [theme.breakpoints.down(768)]: {
            margin: '0 auto 0px',
        },
        [theme.breakpoints.up(768)]: {
            padding: '10px 15px',
        },
        backgroundColor: data?.background_color || '#fff',
    }),

    unt_p: {
        fontWeight: 'bold',
        margin: '5px 0',
        textAlign: 'center',
        fontSize: '10px',
        padding: '0px 5px',
        display: 'flex',
        alignItems: 'center',
    },
    unt_link: {
        textDecoration: 'none',
        fontSize: '11px',
        letterSpacing: '0.42px',
        padding: '5px',
        [theme.breakpoints.down(480)]: {
            fontSize: '9px',
            letterSpacing: 'inherit',
        },
        [theme.breakpoints.up(768)]: {
            fontSize: '13px',
            padding: '0px 5px 4px 5px',
        },
        [theme.breakpoints.up(1025)]: {
            fontSize: '15px',
            paddingBottom: 2,
        },
    },
    radio_link: (data) => ({
        display: 'none',
        [theme.breakpoints.up(1025)]: {
            backgroundColor: data?.offers_button?.button_background_color?.color  || '#fff',
            display: 'block',
            textDecoration: 'none',
            padding: '4px',
            border: '1px solid #e5e5e5',
            borderRadius: '6px',
            '&:hover': {
                cursor: 'pointer',
                textDecoration: 'none',
            },
        },
    }),
    radioContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    },
    radioIcon: {
        height: '29px',
        width: '29px',
        borderRadius: '60px',
        marginRight: '6px',
        backgroundColor: theme.palette.bgHeader,
        '& img': {
            height: '30px',
            width: '30px',
        },
    },
    radioIconSvg: {
        pointerEvents: 'none',
        display: 'block',
        width: '100%',
        height: '100%',
    },
    subscribeBoxMobile: {
        [theme.breakpoints.up(1025)]: {
            display: 'none',
        },
    },
    subscribeBoxDesktop: {
        [theme.breakpoints.down(1025)]: {
            display: 'none',
        },
    },
    radioText: (data) => ({
        color: data?.offers_button?.font_color?.color || theme.palette.bgHeader,
        fontWeight: 'bold',
        fontSize: data?.offers_button?.font_size || '16px',
        margin: 0,
    }),
    radioArrow: {
        height: '25px',
        width: '25px',
        paddingBottom: '1px',
    },
    radioArrowSvg: (data) => ({
        fill: data?.offers_button?.arrow_color?.color || theme.palette.bgHeader,
        pointerEvents: 'none',
        display: 'block',
        width: '100%',
        height: '100%',
    }),
    untBlock: {
        [theme.breakpoints.down(768)]: {
            margin: '8px 8px 4px',
            padding: '3px 10px',
        },
        [theme.breakpoints.down(420)]: {
            padding: '3px',
        },
    },
    untIcon: {
        width: 15,
    },
}));

const determineLocale = () => {
    if (typeof window !== 'undefined' && (window?.location?.href?.includes('canada') || window?.location?.href?.includes('england'))) {
        return false;
    }
    return true;
};
const radioIconBackup = (classes) => (
    <svg
        viewBox="0 0 33 33"
        preserveAspectRatio="xMidYMid meet"
        focusable="false"
        className={classes?.radioIconSvg}
    >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinejoin="round">
            <g id="Group" transform="translate(8, 4)" stroke="#FFF">
                <path d="M14.5714286,12.547619 C14.5714286,16.0128532 11.7154286,18.8214286 8.19642857,18.8214286 C4.67530357,18.8214286 1.82142857,16.0128532 1.82142857,12.547619 L1.82142857,6.27380952 C1.82142857,2.8085754 4.67530357,0 8.19642857,0 C11.7154286,0 14.5714286,2.8085754 14.5714286,6.27380952 L14.5714286,12.547619 L14.5714286,12.547619 Z" />
                <path d="M0,10.3214286 L0,12.3857143 C0,17.0035214 3.63515625,20.6428571 8.25,20.6428571 C12.8627813,20.6428571 16.5,17.0035214 16.5,12.3857143 L16.5,10.3214286" strokeLinecap="round" />
                <path d="M8.5,21.25 L8.5,24.2857143" strokeLinecap="round" />
                <path d="M8.5,0 L8.5,3.03571429" strokeLinecap="round" />
                <path d="M10.9297857,1 L10.9285714,4.03571429" strokeLinecap="round" />
                <path d="M6.07142857,1 L6.07142857,4.03571429" strokeLinecap="round" />
                <path d="M0,12.75 L16.5,12.75" />
                <path d="M1.82142857,6.07142857 L6.07142857,6.07142857" strokeLinecap="round" />
                <path d="M1.82142857,8.5 L6.07142857,8.5" strokeLinecap="round" />
                <path d="M14.1785714,6.07142857 L9.92857143,6.07142857" strokeLinecap="round" />
                <path d="M14.1785714,8.5 L9.92857143,8.5" strokeLinecap="round" />
                <path d="M1.82142857,10.3214286 L6.07142857,10.3214286" strokeLinecap="round" />
                <path d="M14.1785714,10.3214286 L9.92857143,10.3214286" strokeLinecap="round" />
            </g>
        </g>
    </svg>
);

export const GraphqlUnderNavTextWidget = ({
    data, trackUNTEvent, contentType, presentationFamily, passportSignInData, passportOpenSideBar,
}) => {
    const countryName = useSelector(selectors.country);
    const classes = useStyles(data);
    const ffWhichPassportSidebarEnabledVariant = useSelector(getFeatureFlag('which-passport-sidebar-enabled-variant'));
    const ffIsRadioOffersButtonEnabled = useSelector(getFeatureFlag('is-radio-offers-button-enabled'));
    // Persist data layer
    trackData({
        untData: data,
        action: 'track_unt_data',
    });
    /**
     * @intention - load css conditionally on homepage flowers
     */
    const loadCss = () => {
        if (contentType === 'homepage' && presentationFamily === 'flower') {
            return classes?.untBlock;
        } return null;
    };
    const countryNameLowerCase = countryName.toLowerCase();
    if ((countryNameLowerCase !== '' && countryNameLowerCase !== 'united kingdom'  && countryNameLowerCase !== 'united states'  && countryNameLowerCase !== 'canada') && presentationFamily !== 'food') {
        return null;
    }

    let untTitle = data?.link?.title || '';
    let untLink = data?.link?.href || '';
    const orderTracking = data?.order_tracking || {};
    const subscribeBoxViewport = data?.subscribe_box?.viewport || 'desktop';
    const multiUntSliderData = data?.multiple_unt_slider?.unt_array || [];
    const buttonHeight  = data?.offers_button?.button_height;
    const buttonWidth = data?.offers_button?.button_width;
    const radioicon = data?.offers_button?.icon?.url;
    const buttonLink = data?.offers_button?.link?.href || '/radio-interstitial-form';
    const buttonText = data?.offers_button?.link?.title;

    const handleClick = (event) => {
        // VARIANT A is "Passport Enrolment Page"
        // CONTROL is "Passport Side Bar"
        if (ffWhichPassportSidebarEnabledVariant?.variant === 'CONTROL' && passportSignInData?.underNavText && passportSignInData?.underNavLink) {
            event.preventDefault();
            passportOpenSideBar();
        } else if (data?.ab_testing?.variant !== 'CONTROL') {
            trackUNTEvent({
                eventCategory: data?.ab_testing?.tracking_event_category || 'UNT',
                eventAction: data?.ab_testing?.tracking_event_action || data?.link?.title,
                eventLabel: data?.ab_testing?.tracking_event_label || '<<pageType>>',
            });
        }
    };
    if (passportSignInData?.underNavText && passportSignInData?.underNavLink) {
        untTitle = passportSignInData?.underNavText;
        untLink = passportSignInData?.underNavLink;
    }

    // Styles controlled from CMS
    const containerStyleOverrides = {
        backgroundColor: data.background_color,
    };

    const linkStyleOverrides = {
        color: data.font_color,
        fontSize: data.font_size_in_px,
        sliderWidthOverride: data.multiple_unt_slider?.width_override,
    };

    const siteContainerStyleOverrides = {
        height: `${data?.height_in_px}px`,
        justifyContent: data?.alignment?.toLowerCase() === 'right' ? 'space-between' : data.alignment,
    };

    return (
        <div id="unt" className={`${classes?.unt} ${loadCss()}`} style={containerStyleOverrides}>
            <div className={classes?.siteContainer} style={siteContainerStyleOverrides}>
                {
                    multiUntSliderData.length > 1 ? (
                        <MultiUntSlider
                            multiUntSliderData={multiUntSliderData}
                            linkStyleOverrides={linkStyleOverrides}
                            rotateSpeedOverride={data?.multiple_unt_slider?.rotate_speed_override}
                            trackEvent={trackUNTEvent}
                        />
                    ) : (
                        <p className={classes?.unt_p}>
                            {data?.image?.url && <ResponsiveImage path={data.image.url} alt={data?.image?.description} className={classes.untIcon} /> }
                            <Link
                                className={classes?.unt_link}
                                style={linkStyleOverrides}
                                to={untLink}
                                onClick={(event) =>  handleClick(event)}
                            >
                                {untTitle}
                            </Link>
                        </p>
                    )
                }
                {orderTracking && orderTracking?.show ? <GraphqlUNTOrderTracking orderTracking={orderTracking} /> : null}
                {data?.subscribe_box?.is_enabled ? (
                    <div
                        className={`${subscribeBoxViewport === 'Desktop' || subscribeBoxViewport === 'Mobile' ? classes[`subscribeBox${subscribeBoxViewport}`] : ''}`}
                    >
                        <EmailSubscribeBox data={data?.subscribe_box} />
                    </div>
                ) : (determineLocale() && ffIsRadioOffersButtonEnabled && (
                    <Link
                        className={classes?.radio_link}
                        to={buttonLink}
                        onClick={() => trackUNTEvent({
                            eventCategory: 'UNT', eventAction: 'Radio Button', eventLabel: '<<pageType>>',
                        })}
                    >
                        <div
                            className={classes?.radioContainer}
                            style={{  width: buttonWidth, height: buttonHeight }}
                        >
                            <div className={classes?.radioIcon}>
                                {radioicon ? <img src={radioicon} alt="radio" /> : radioIconBackup(classes) }
                            </div>
                            <p
                                className={classes?.radioText}
                            > {buttonText || 'RADIO OFFERS | CLICK HERE'}
                            </p>

                            <div
                                className={classes?.radioArrow}
                            >
                                <svg
                                    viewBox="0 0 24 24"
                                    preserveAspectRatio="xMidYMid meet"
                                    focusable="false"
                                    className={classes?.radioArrowSvg}
                                >
                                    <g>
                                        <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </Link>
                )
                )}
            </div>
        </div>
    );
};

GraphqlUnderNavTextWidget.defaultProps = {
    passportSignInData: {},
    passportOpenSideBar: () => { },
};
GraphqlUnderNavTextWidget.propTypes = {
    data: object.isRequired,
    trackUNTEvent: func.isRequired,
    contentType: string.isRequired,
    presentationFamily: string.isRequired,
    passportSignInData: shape({
        underNavText: string,
    }),
    passportOpenSideBar: func,
};

export default GraphqlUnderNavTextWidget;
